import { Builder } from '@builder.io/react'
import useGetProduct from '../../hooks/useGetProduct'
import useGetPrice from '../../hooks/useGetPrice'
import image from './images/percent.svg'

export default function PercentOff({ sku }) {
    const product = useGetProduct(sku)
    const { price } = useGetPrice(product)

    const percentageOff = (1 - price?.promotion_price / price?.price) * 100
    const inRange = (percentageOff > 0) && (percentageOff < 100)

    if (!inRange) return
    else return `${percentageOff.toFixed(0)}% OFF`
}

Builder.registerComponent(PercentOff, {
    name: 'Percent Off',
    image,
    inputs: [
        {
            name: 'sku',
            type: 'string',
            required: true,
        }
    ]
})