import React from 'react'
import useGetPrice from '../hooks/useGetPrice'

export default function Price(props) {
    const {
        item,
        product,
        quantity = 1,
        style = {},
        className = '',
        eswPriceClassName = '',
    } = props

    const { price, baseprice } = useGetPrice(product)
    const discount = item?.discount_applied ? parseFloat(item?.discount_applied) : 0

    let value = (price?.promotion_price ?? price?.price) * quantity
    if (value && discount) value -= discount

    let basevalue = (baseprice?.promotion_price ?? baseprice?.price) * quantity
    if (basevalue && discount) basevalue -= discount

    const price_display = price ? `${price.currency_symbol}${value.toFixed(2)}` : ''
    const baseprice_display = baseprice ? `${baseprice.currency_symbol}${basevalue.toFixed(2)}` : ''
    
    return (
        <span
            style={style}
            className={`${className} ${eswPriceClassName}`}
            data-bp-lti={baseprice_display}
        >
            {price_display}
        </span>
    )
}
